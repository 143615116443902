exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/Index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/Landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/Thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

